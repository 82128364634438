import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Animated, TouchableWithoutFeedback, Platform } from "react-native";
import Content from "./Content";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: new Animated.Value(props.visible ? 1 : 0)
    }
    this.displayModal = props.visible;
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.visible === true) {
      this.displayModal = true;
    }
    if (nextProps.visible === false) {
      this.hideModal();
      return false
    }
    return true;
  }

  componentDidUpdate() {
    if (this.displayModal) {
      this.showModal();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  preventDefault = (e) => {
    if (Platform.OS === "web") {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  showModal = () => {
    const { opacity } = this.state;
    Animated
      .timing(opacity, {
        toValue: 1,
        duration: 200
      })
      .start();
  }

  hideModal = () => {
    const { opacity } = this.state;
    Animated
      .timing(opacity, {
        toValue: 0,
        duration: 200
      })
      .start(() => {
        this.displayModal = false;
        if (this.mounted) {
          this.forceUpdate();
        }
      });
  }

  render() {
    const {
      visible,
      onCancel,
      children,
      ...restProps
    } = this.props;
    const {
      opacity
    } = this.state;

    if (!this.displayModal) {
      return null;
    }

    const overlayStyles = {
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      position: "fixed",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 1000,
      opacity
    };

    return (
      <TouchableWithoutFeedback onPress={onCancel}>
        <Animated.View style={overlayStyles} onClick={this.preventDefault} >
          <Content
            onCancel={onCancel}
            {...restProps}
          >
            {children}
          </Content>
        </Animated.View>
      </TouchableWithoutFeedback>
    );
  }
}

// // Modal without animation; to be used when AnimatedModal have problems
// const Modal = ({
//   visible,
//   children,
//   onCancel,
//   ...restProps
// }) => {
//   if (!visible) {
//     return null;
//   }

//   const overlayStyles = {
//     alignItems: "center",
//     justifyContent: "center",
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     position: "absolute",
//     left: 0,
//     top: 0,
//     width: "100%",
//     height: "100%",
//     zIndex: 1000
//   };

//   return (
//     <TouchableWithoutFeedback onPress={onCancel}>
//       <View style={overlayStyles}>
//         <Content
//           onCancel={onCancel}
//           {...restProps}
//         >
//           {children}
//         </Content>
//       </View>
//     </TouchableWithoutFeedback>
//   );
// };

const WebModalWithPortal = (props) => {
  if (!process.browser) {
    return null;
  }

  return ReactDOM.createPortal(
    <Modal {...props} />,
    document.getElementById("__next")
  );
};

export default WebModalWithPortal;
