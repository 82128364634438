import React from "react";
import {
  Platform,
  Dimensions,
  StyleSheet,
  View,
  TouchableWithoutFeedback
} from "react-native";
import SButton from "../../../SButton";
import SText from "../../../SText";

const Content = ({
  onOk,
  onCancel,
  title,
  titleProps,
  message,
  messageProps,
  okText,
  okTextProps,
  okType,
  cancelText,
  cancelTextProps,
  cancelType,
  children,
  modalContainerStyle,
  modalBodyStyle,
  modalMessageStyle,
  modalFooterStyle,
  modalButtonStyle
}) => {
  const renderButton = ({ type, text, textProps, onPress }) => (
    <SButton
      type={type}
      text={text}
      textProps={{
        weight: "bold",
        ...textProps
      }}
      containerStyle={[styles.button, modalButtonStyle]}
      onPress={onPress}
    />
  );

  return (
    <TouchableWithoutFeedback pointerEvents="box-none">
      <View style={[styles.container, modalContainerStyle]}>
        {children || (
          <View style={[styles.body, modalBodyStyle]}>
            {title ? (
              <SText size={20} weight="bold" {...titleProps}>
                {title}
              </SText>
            ) : null}
            <View style={[styles.message, modalMessageStyle]}>
              {message ? (
                <SText size={16} color="secondary" {...messageProps}>
                  {message}
                </SText>
              ) : null}
            </View>
            <View style={[styles.footer, modalFooterStyle]}>
              {renderButton({
                type: cancelType,
                text: cancelText,
                textProps: cancelTextProps,
                onPress: onCancel
              })}
              {renderButton({
                type: okType,
                text: okText,
                textProps: okTextProps,
                onPress: onOk
              })}
            </View>
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: 300,
    backgroundColor: "white",
    borderRadius: 3,
    ...Platform.select({
      web: {
        marginHorizontal: "auto",
        maxWidth: Dimensions.get("window").width < 800 ? "100%" : 860
      },
      android: {
        marginHorizontal: 10
      }
    }),
    zIndex: 1010
  },
  body: {
    padding: 16
  },
  message: {
    marginTop: 16,
    marginBottom: 22
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  button: {
    marginHorizontal: 5,
    minWidth: 88
  }
});

export default Content;
