
import PropTypes from "prop-types";
import SModal from "./Modal";

// Note: For displaying custom component pass that component as children
// e.g. <SModal ...><CustomComponent /></SModal>
SModal.defaultProps = {
  visible: false,
  title: "",
  message: "",
  okText: "OK",
  okType: "text",
  onOk: undefined,
  cancelText: "CANCEL",
  cancelType: "text",
  onCancel: undefined,
  animationIn:"fadeIn",
  animationOut:"fadeOut"
}

SModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  okText: PropTypes.string,
  okType: PropTypes.oneOf(["primary", "secondary", "text"]),
  onOk: PropTypes.func, // Called when a user clicks Ok button
  cancelText: PropTypes.string,
  cancelType: PropTypes.oneOf(["primary", "secondary", "text"]),
  onCancel: PropTypes.func // Called when a user clicks Backdrop or Cancel button
};


export default SModal;
